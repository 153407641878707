
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { Modal } from "bootstrap";
import { number } from "yup/lib/locale";
import Products from "@/views/apps/product/Products.vue";
import {ActionsRe} from "@/store/enums/StoreResearchEnums";

export default defineComponent({
  name: "add-product-price-modal",
  components: {
    
    },
    methods: {
      onChange() 
      { 
        this.formData.category_select = '' 
        }
  },

   
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const addPriceModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();
    const router = useRouter(); 
    const id1data =ref("");
    const formData = ref({
      name: "",     
      parent_select: "", 
      value_select: "",
      city_select: "",
      category_select : "",
      incoterm_select: "",
      delivery_select: "",
    });

    onMounted( async () => {
      await setIncotermData()
      await setDeliveryData()
      await setCurrencyData()
      await setCityData()
      await setParentCatData()
      await setChildCatData()
    });
    
    const rules = ref({
      name: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",  
             
        },  
      ],   
      city_select: [
        {
          required: true,
          message: "City is required",
          trigger: "change",  
        },  
      ],   
      parent_select: [
        {
          required: true,
          trigger: "change",  
        }
      ],
      category_select: [
        {
          required: true,
          message: "Category is required",
          trigger: "change",  
        },  
      ],  
      value_select: [
        {
          required: true,
          message: "Currency is required",
          trigger: "change",  
        },  
      ], 
      incoterm_select: [
        {
          required: true,
          message: "Incoterm is required",
          trigger: "change",  
        },  
      ], 
      delivery_select: [
        {
          required: true,
          message: "Delivery is required",
          trigger: "change",  
        },  
      ], 
    });

    

    const incoterm = ref([]);
    const setIncotermData = async () => {
        //loading.value = true;
        try {
          await store.dispatch(Actions.CUST_PRICE_INCOTERM).then(({ data }) => {
          incoterm.value = data;
          console.log(incoterm.value)
          //loading.value = false;
         
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const delivery = ref([]);
    const setDeliveryData = async () => {
        //loading.value = true;
        try {
          await store.dispatch(Actions.CUST_PRICE_DELIVERY_TERM).then(({ data }) => {
          delivery.value = data;
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const city = ref([]);
    const setCityData = async () => {
       // loading.value = true;
        try {
          await store.dispatch(Actions.CUST_CITY_LIST).then(({ data }) => {
          city.value = data;
          console.log(city.value)
          //loading.value = false;
         
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const currency = ref([]);
    const setCurrencyData = async () => {
        //loading.value = true;
        try {
          await store.dispatch(Actions.CUST_PRICE_CURRENCY).then(({ data }) => {
          currency.value = data;
          console.log(currency.value)
          //loading.value = false;
         
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const parentcat = ref([]);
     const setParentCatData = async () => {
        loading.value = true;
        try {
          var values = {}
          await store.dispatch(Actions.CUST_NEWS_PARENT_CATEGORY, values).then(({ data }) => {
          parentcat.value = data;
          loading.value = false;
         
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const childcat = ref([]);
     const setChildCatData = async () => {
      
        loading.value = true;
        try {
          var values = {
            "category_id": formData.value.parent_select
            }
          await store.dispatch(Actions.CUST_NEWS_CHILD_CATEGORY, values).then(({ data }) => {
          childcat.value = data;
          loading.value = false;
         
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

     const setPriceData = async (data) => {
      
      const db_data = {
        "price_template_id": 0,
        "template_name" : data.name,
        "category_id": data.category_select,
        "city_id": data.city_select,    
        "currency_id": data.value_select,
        "incoterm_id": data.incoterm_select,
        "delivery_term_id": data.delivery_select,
        "active":true,
  
        }
        console.log(db_data);
        await store.dispatch(Actions.CUST_ADD_PRICE_INDEX, db_data).then(({ data }) => {
          if (data){
        
            setTimeout(() => {
              //loading.value = false;
//
              Swal.fire({
                text: "Price Index added successfully.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addPriceModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            //loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });

    }

    const submit = async () => {
      loading.value = true;
      if (!formRef.value) {
        loading.value = false;
        return;
      }
      formRef.value.validate (async (valid) => {

        if (valid) {

          await setPriceData(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }
      });    
    };

    return {      
      formData,      
      rules,
      submit,
      formRef,
      loading,
      addPriceModalRef,
      setCityData,
      setCurrencyData,
      setParentCatData,
      setChildCatData,
      setDeliveryData,
      setIncotermData,
      incoterm,
      delivery,
      currency,
      city,
      parentcat,
      childcat,
    };
  },
});
