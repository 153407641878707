
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {designation_list} from "@/core/data/genericData";
import axios from "axios";
import JwtService from "@/core/services/JwtService";
import {mask} from 'vue-the-mask'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import { Modal } from "bootstrap";
import { number, string } from "yup/lib/locale";
import {ActionsRe} from "@/store/enums/StoreResearchEnums";


export default defineComponent({
  name: "add-branch-modal",
  directives: {mask},
  props: {    
    id: { required: true }
  },
  components: {
    
  },
  
  setup(props) {
    
    const store = useStore();
    const designation_item = designation_list;    
    const formRef = ref<null | HTMLFormElement>(null);
    const addPriceModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const router = useRouter();    
    const loadingData = ref<boolean>(true);
    const attribute = ref([]);
    const formData = ref({
      price: "",
      fromduedate: "",
    }); 

    const rules = ref({
      fromdueDate: [
        {
          required: true,
          message: "Date is required",
          trigger: "change",          
        },        
      ],
       price: [
        {
          required: true,
          message: "Price is required",
          trigger: "change",     
          min : 0,     
        },        
      ],
    });

    var paginationData = ref({})
    
    paginationData.value = {      
        start : 0,
        end : 0,
        total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

    onMounted( async () => {
      //loading.value = false;
    });

     const setPriceLinkData = async (data) => {

      var price_template_id = ((document.querySelector("input[name=price_template_id]") as HTMLInputElement).value);
      console.log(price_template_id);
      
      const db_data = {
        "daily_reading_id":0,
        "price_template_id":Number(price_template_id),
        "reading_price": data.price,
        "reading_date": data.fromdueDate,
        "uom_id": 1
        }
        await store.dispatch(Actions.CUST_PRICE_ADD, db_data).then(({ data }) => {
          if (data){
        
            setTimeout(() => {
              //loading.value = false;
//
              Swal.fire({
                text: "Price added successfully.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addPriceModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            //loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });

    }

    const submit = async () => {
      
      loading.value = true;

      if (!formRef.value) {
        //loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {
        if (valid) {
          await setPriceLinkData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };   
    
    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      attribute,
      addPriceModalRef,
    };
  },
});
