
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {designation_list} from "@/core/data/genericData";
import axios from "axios";
import JwtService from "@/core/services/JwtService";
import {mask} from 'vue-the-mask'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import { Modal } from "bootstrap";
import { number, string } from "yup/lib/locale";
import {ActionsRe} from "@/store/enums/StoreResearchEnums";


export default defineComponent({
  name: "add-branch-modal",
  directives: {mask},
  props: {    
    id: { required: true }
  },
  components: {
    
  },
  
  setup(props) {
    
    const store = useStore();
    const designation_item = designation_list;    
    const formRef = ref<null | HTMLFormElement>(null);
    const addPriceAttributeModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const router = useRouter();    
    const loadingData = ref<boolean>(true);

    const attribute = ref([]);
    const attribute_value = ref([]) 

    const formData = ref({
      price_attribute_type_select: "",
      price_attribute_select: "",
      price_template_id: "",
      
    }); 
   
    const rules = ref({
      // attribute_type_select: [
      //   {
      //     required: true,
      //     message: "Attribute is required",
      //     trigger: "change",          
      //   },        
      // ],
      //  attribute_select: [
      //   {
      //     required: true,
      //     message: "Attribute Value is required",
      //     trigger: "change",          
      //   },        
      // ],
    });

    var paginationData = ref({})
    
    paginationData.value = {      
        start : 0,
        end : 0,
        total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

    onMounted( async () => {
      await setAttribute()
      //await setAttrValue()
    });

    const setAttrValue = async () => {
      
      try {
        var values = { 
          "price_template_id"  : (document.querySelector('#price_template') as HTMLInputElement).value ,
          "attribute_id"  : formData.value.price_attribute_type_select
          }
        await store.dispatch(Actions.CUST_ATTRIBUTE_VALUE_LIST, values).then(({ data }) => {
        attribute_value.value = data;
          
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }
    }

    const setAttribute = async () => {
      try {
            var values = { "price_template_id"  : (document.querySelector('#price_template') as HTMLInputElement).value }
            await store.dispatch(Actions.CUST_PRICE_ATTRIBUTE, values).then(({ data }) => {
            attribute.value = data.attribute_list;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }
    }

     const setAttributeLinkData = async (data) => {

      var price_template_id = ((document.querySelector("input[name=price_template]") as HTMLInputElement).value);
      
      const db_data = {
        "price_template_id": Number(price_template_id),
        "attribute_id": data.price_attribute_type_select,
        "sr_no": data.price_attribute_select,
        "user_id":1
        }
        await store.dispatch(Actions.CUST_PRICE_ATTRIBUTE_SAVE, db_data).then(({ data }) => {
          if (data){
        
            setTimeout(() => {
              //loading.value = false;
//
              Swal.fire({
                text: "Attribute has been successfully mapped.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addPriceAttributeModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            //loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });

    }

    const submit = async () => {
      
      loading.value = true;

      if (!formRef.value) {
        //loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {
        if (valid) {
          await setAttributeLinkData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };   
    
    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      attribute,
      attribute_value,
      addPriceAttributeModalRef,
      setAttribute,
      setAttrValue,
    };
  },
});
